<template>
  <div id="lom-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>Create New LO</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-text>
          <h6>* indicates required field</h6>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-container>
            <v-form ref="form2" v-model="valid1" lazy-validation>
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*Year</label>
                  <v-autocomplete
                    @change="getZones"
                    :reduce="(option) => option.value"
                    :items="JciYearCodeOptions"
                    :rules="JciYearCodeRules"
                    :loading="JciYearCodeOptionsLoading"
                    v-model="JciYearCode"
                    hint="Select Year"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*Area</label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="AreaCodeOptions"
                    :rules="AreaCodeRules"
                    :loading="AreaCodeOptionsLoading"
                    v-model="AreaCode"
                    hint="Select Area"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*Region</label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="RegionCodeOptions"
                    :rules="RegionCodeRules"
                    :loading="RegionCodeOptionsLoading"
                    v-model="RegionCode"
                    hint="Select Region"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*Zone</label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    :loading="ZoneCodeOptionsLoading"
                    v-model="ZoneCode"
                    hint="Select zone"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <h4 class="text-primary">LOM details</h4>
              <br />
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*LOM Name</label>
                  <v-text-field
                    v-model="LomName"
                    :rules="LomNameRules"
                    hint="Enter LOM Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*Charter Date</label>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="CharterDate"
                        label="Charter Date"
                        hint="Enter LOM Charter Date"
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="CharterDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*Affiliation Date</label>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="AffiliationDate"
                        label="Affiliation Date"
                        hint="Enter LOM Affiliation Date"
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="AffiliationDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*LOM Status</label>
                  <v-select
                    :reduce="(option) => option.value"
                    :items="LomStatusOptions"
                    :rules="LomStatusRules"
                    v-model="LomStatus"
                    required
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*State</label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="StateIdOptionsLoading"
                    :items="StateIdOptions"
                    :rules="StateIdRules"
                    v-model="StateId"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*District</label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="DistrictIdOptionsLoading"
                    :items="DistrictIdOptions"
                    :rules="DistrictIdRules"
                    v-model="DistrictId"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*City</label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="CityIdOptionsLoading"
                    :items="CityIdOptions"
                    :rules="CityIdRules"
                    v-model="CityId"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <h4 class="text-primary">Sponsor details</h4>
              <br />
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>*Sponsored by</label>
                  <v-select
                    :reduce="(option) => option.value"
                    :items="SponsorTypeOptions"
                    :rules="SponsorTypeRules"
                    v-model="SponsorType"
                    required
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3" v-if="SponsorType == 1">
                  <label>*Sponsor member name</label>
                  <v-text-field
                    v-model="SponsorMemberName"
                    :rules="SponsorMemberNameRules"
                    hint="Enter sponsor member name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3" v-if="SponsorType == 2">
                  <label>*Sponsor Zone</label>
                  <v-autocomplete
                    @change="getLomCodeOptions"
                    :reduce="(option) => option.value"
                    :items="SponsorZoneCodeOptions"
                    :rules="SponsorZoneCodeRules"
                    :loading="SponsorZoneCodeOptionsLoading"
                    v-model="SponsorZoneCode"
                    hint="Select zone"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3" v-if="SponsorType == 2">
                  <label>*Sponsor LOM</label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="SponsorLomCodeOptions"
                    :rules="SponsorLomCodeRules"
                    :loading="SponsorLomCodeOptionsLoading"
                    v-model="SponsorLomCode"
                    hint="Select LOM"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    color="#8950FC"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="
                      btn btn-primary
                      font-size-h6
                      px-15
                      py-4
                      my-3
                      mr-3
                      white--text
                    "
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <br /><br />
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,
      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [(v) => !!v || "Region is required"],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      SponsorType: "",
      SponsorTypeRules: [(v) => !!v || "Sponsor type is required"],
      SponsorTypeOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Member" },
        { value: 2, text: "LOM" },
      ],

      SponsorMemberName: "",
      SponsorMemberNameRules: [(v) => !!v || "Sponsor member name is required"],

      SponsorZoneCodeRules: [(v) => !!v || "Zone is required"],
      SponsorZoneCode: "",
      SponsorZoneCodeOptions: [],
      SponsorZoneCodeOptionsLoading: false,

      SponsorLomCodeRules: [(v) => !!v || "LOM is required"],
      SponsorLomCode: "",
      SponsorLomCodeOptions: [],
      SponsorLomCodeOptionsLoading: false,

      LomName: "",
      LomNameRules: [(v) => !!v || "LOM name is required"],

      CharterDate: "",
      CharterDateRules: [(v) => !!v || "Charter Date is required"],
      menu1: false,

      AffiliationDate: "",
      AffiliationDateRules: [(v) => !!v || "Affiliation Date is required"],
      menu2: false,

      LomStatus: "",
      LomStatusRules: [(v) => !!v || "LOM Status is required"],
      LomStatusOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Permanent LOM" },
        { value: 2, text: "Provisional LOM" },
      ],

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.LoadingFlag = false;
      this.AreaCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.LoadingFlag = false;
      this.RegionCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    SponsorZoneCodeOptions: function () {
      console.log("watch SponsorZoneCodeOptions");
      this.LoadingFlag = false;
      this.SponsorZoneCodeOptionsLoading = false;
    },
    SponsorLomCodeOptions: function () {
      console.log("watch SponsorLomCodeOptions");
      this.LoadingFlag = false;
      this.SponsorLomCodeOptionsLoading = false;
    },
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
    },
    StateId: function () {
      console.log("watch StateId");
      this.LoadingFlag = true;
      this.DistrictIdOptionsLoading = true;
      this.getDistrictIdOptions(
        "DistrictId",
        "DistrictIdOptions",
        this.StateId
      );
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      this.LoadingFlag = true;
      this.CityIdOptionsLoading = true;
      this.getCityIdOptions("CityId", "CityIdOptions", this.DistrictId);
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    resetForm() {
      this.$refs.form2.reset();
      this.CharterDate = "";
      this.AffiliationDate = "";
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getZones() {
      this.ZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("ZoneCode", "ZoneCodeOptions");
      this.SponsorZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("SponsorZoneCode", "SponsorZoneCodeOptions");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getJciYearCodeOptions();
      this.getAreaCodeOptions();
      this.getRegionCodeOptions();
      // this.getZones();
      this.StateIdOptionsLoading = true;
      this.getStateIdOptions("StateId", "StateIdOptions");
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getAreaCodeOptions() {
      this.LoadingFlag = true;
      this.AreaCodeOptionsLoading = true;
      console.log("getAreaCodeOptions called");
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/area/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getRegionCodeOptions() {
      this.LoadingFlag = true;
      this.RegionCodeOptionsLoading = true;
      console.log("getRegionCodeOptions called");
      var selectbox1_source = "RegionCode";
      var selectbox1_destination = "RegionCodeOptions";
      var selectbox1_url = "api/region/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions(source, destination) {
      this.LoadingFlag = true;
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", yearCode=" +
          yearCode
      );
      if (source != "" && destination != "" && yearCode != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
          NotAvailable: 0,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      this.LoadingFlag = true;
      console.log("getLomCodeOptions called");
      var yearCode = this.JciYearCode;
      var SponsorZoneCode = this.SponsorZoneCode;
      console.log(
        "SponsorZoneCode=" + SponsorZoneCode + ", yearCode=" + yearCode
      );
      if (SponsorZoneCode != "" && yearCode != "") {
        var selectbox1_source = "SponsorLomCode";
        var selectbox1_destination = "SponsorLomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
          ZoneCode: SponsorZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        if (SponsorZoneCode == "") {
          message += "Sponsor Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getStateIdOptions(source, destination) {
      console.log("getStateIdOptions called");
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictIdOptions(source, destination, StateId) {
      console.log("getDistrictIdOptions called StateId=" + StateId);
      if (source != "" && destination != "" && StateId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions(source, destination, DistrictId) {
      console.log("getCityIdOptions called DistrictId=" + DistrictId);
      if (source != "" && destination != "" && DistrictId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var CharterDate = this.CharterDate;
      var AffiliationDate = this.AffiliationDate;
      console.log(
        "validate1=" +
          validate1 +
          ", CharterDate=" +
          CharterDate +
          ", AffiliationDate=" +
          AffiliationDate
      );

      if (validate1 && CharterDate != "" && AffiliationDate != "") {
        this.SubmitFlag = true;
        this.rows = [];
        this.zone = {};
        this.lom = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/old-lom/store";
        var upload = {
          YearCode: this.JciYearCode,
          AreaCode: this.AreaCode,
          RegionCode: this.RegionCode,
          ZoneCode: this.ZoneCode,
          LomName: this.LomName,
          CharterDate: this.CharterDate,
          AffiliationDate: this.AffiliationDate,
          SponsorType: this.SponsorType,
          SponsorMemberName: this.SponsorMemberName,
          SponsorZoneCode: this.SponsorZoneCode,
          SponsorLomCode: this.SponsorLomCode,
          LomStatus: this.LomStatus,
          StateId: this.StateId,
          DistrictId: this.DistrictId,
          CityId: this.CityId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (CharterDate == "") {
          message += "Charter Date should not be empty. ";
        }
        if (AffiliationDate == "") {
          message += "Affiliation Date should not be empty. ";
        }
        thisIns.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;
    this.refreshPageData();
    this.tableColumns1.push(
      // { text: "Actions", value: "actions" },
      { text: "Status", value: "ActiveStatusTxt" },
      { text: "LOM Name", value: "LomName" },
      { text: "Charter Date", value: "CharterDateTxt" },
      { text: "Total Members", value: "TotalMembers" },
      { text: "Actions", value: "actions" }
    );
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>