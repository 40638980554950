<template>
  <div id="area-edit">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="refreshPageData"
                color="#a4c639"
              >Refresh</v-btn>
              <br />
            </v-col>
          </v-row>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" md="12">
                  <h3>Lo Details</h3>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Lo Name
                    </h6>
                  </label>
                  <v-text-field
                    v-model="LomName"
                    :rules="LomNameRules"
                    placeholder="Lo Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Zone
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    :loading="ZoneCodeOptionsLoading"
                    v-model="ZoneCode"
                    hint="Select Zone"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> State
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="StateCodeOptionsLoading"
                    :items="StateCodeOptions"
                    :rules="StateCodeRules"
                    v-model="StateCode"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> District
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="DistrictCodeOptionsLoading"
                    :items="DistrictCodeOptions"
                    :rules="DistrictCodeRules"
                    v-model="DistrictCode"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> City
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="CityCodeOptionsLoading"
                    :items="CityCodeOptions"
                    :rules="CityCodeRules"
                    v-model="CityCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Lom Charter Date
                    </h6>
                  </label>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="LomCharterDate"
                        :rules="LomCharterDateRules"
                        readonly
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="LomCharterDate" @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Lom Affiliation Date
                    </h6>
                  </label>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="LomAffiliationDate"
                        :rules="LomAffiliationDateRules"
                        readonly
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="LomAffiliationDate" @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>GST Number</h6>
                  </label>
                  <v-text-field
                    v-model="GstNumber"
                    :rules="GstNumberRules"
                    placeholder="GST Number"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Lo Status
                    </h6>
                  </label>
                  <v-select
                    :reduce="(option) => option.value"
                    :items="LomStatusOptions"
                    :rules="LomStatusRules"
                    v-model="LomStatus"
                    dense
                    clearable
                    required
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <h3>Sponsor Lo Details</h3>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Sponsor Type
                    </h6>
                  </label>
                  <v-select
                    :reduce="(option) => option.value"
                    :items="SponsorTypeOptions"
                    :rules="SponsorTypeRules"
                    v-model="SponsorType"
                    dense
                    clearable
                    required
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4" v-if="SponsorType == 1">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Sponsor LO
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="SponsorLomCodeOptions"
                    :rules="SponsorLomCodeRules"
                    :loading="SponsorLomCodeOptionsLoading"
                    v-model="SponsorLomCode"
                    hint="Select Sponsor LO"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4" v-if="SponsorType == 2">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Sponsor Details
                    </h6>
                  </label>
                  <v-text-field
                    v-model="SponsorDetails"
                    :rules="SponsorDetailsRules"
                    placeholder="Sponsor Details"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    EditRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,

      menu1: false,
      menu2: false,

      LomCode: "",
      LomName: "",
      LomCharterDate: "",
      LomAffiliationDate: "",
      GstNumber: "",
      SponsorDetails: "",

      LomNameRules: [v => !!v || "Lo Name is required"],
      LomCharterDateRules: [v => !!v || "Lo Charter Date is required"],
      LomAffiliationDateRules: [v => !!v || "Lo Affiliation Date is required"],
      GstNumberRules: [],
      SponsorDetailsRules: [v => !!v || "Sponsor Details is required"],

      StateCodeRules: [v => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [v => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [v => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      LomStatus: "",
      LomStatusRules: [v => !!v || "Lo Status is required"],
      LomStatusOptions: [
        { value: "", text: "--select--" },
        { value: 1, text: "Active" },
        { value: 2, text: "Provisional" },
        { value: 3, text: "Suspended" },
        { value: 4, text: "Dissaffiliated" }
      ],

      SponsorType: "",
      SponsorTypeRules: [v => !!v || "Sponsor Type is required"],
      SponsorTypeOptions: [
        { value: "", text: "--select--" },
        { value: 1, text: "By Lo" },
        { value: 2, text: "By Member" }
      ],

      ZoneCodeRules: [v => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      SponsorLomCodeRules: [v => !!v || "LO is required"],
      SponsorLomCode: "",
      SponsorLomCodeOptions: [],
      SponsorLomCodeOptionsLoading: false,

      rows: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        this.StateCodeOptionsLoading = true;
        this.getStateCodeOptions("StateCode", "StateCodeOptions");
        this.getZoneCodeOptions();
        this.getLomCodeOptions();
        this.getTableRecords();
      }
    },
    StateCodeOptions: function() {
      console.log("watch StateCodeOptions");
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function() {
      console.log("watch StateCode");
      this.DistrictCodeOptionsLoading = true;
      this.getDistrictCodeOptions(
        "DistrictCode",
        "DistrictCodeOptions",
        this.StateCode
      );
    },
    DistrictCodeOptions: function() {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function() {
      console.log("watch DistrictCode");
      this.CityCodeOptionsLoading = true;
      this.getCityCodeOptions("CityCode", "CityCodeOptions", this.DistrictCode);
    },
    CityCodeOptions: function() {
      console.log("watch CityCodeOptions");
      this.CityCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function() {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    SponsorLomCodeOptions: function() {
      console.log("watch SponsorLomCodeOptions");
      this.SponsorLomCodeOptionsLoading = false;
    },
    rows: function() {
      console.log("watch rows");

      this.LomCode = this.rows.LomId;
      this.LomName = this.rows.LomName;
      this.LomCharterDate = this.rows.CharterDate;
      this.LomAffiliationDate = this.rows.AffiliationDate;
      this.StateCode = this.rows.StateId;
      this.DistrictCode = this.rows.DistrictId;
      this.CityCode = this.rows.CityId;
      this.ZoneCode = this.rows.ZoneId;
      this.SponsorLomCode = this.rows.SponsorLomId;
      this.GstNumber = this.rows.GstNumber;
      this.SponsorDetails = this.rows.SponsorDetails;
      this.LomStatus = this.rows.LomStatus;
      this.SponsorType =
        this.SponsorDetails != null &&
        this.SponsorDetails != undefined &&
        this.SponsorDetails != ""
          ? 2
          : 1;
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "area",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    getStateCodeOptions(source, destination) {
      console.log("getStateCodeOptions called");
      if (source != "" && destination != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictCodeOptions(source, destination, StateCode) {
      console.log("getDistrictCodeOptions called StateCode=" + StateCode);
      if (source != "" && destination != "" && StateCode != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions(source, destination, DistrictCode) {
      console.log("getCityCodeOptions called DistrictCode=" + DistrictCode);
      if (source != "" && destination != "" && DistrictCode != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getZoneCodeOptions() {
      this.ZoneCodeOptionsLoading = true;
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/zone/options";
        var selectbox1_conditions_array = {
          UserInterface: 1
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      this.SponsorLomCodeOptionsLoading = true;
      console.log("getLomCodeOptions called");
      var selectbox1_source = "SponsorLomCode";
      var selectbox1_destination = "SponsorLomCodeOptions";
      var selectbox1_url = "api/lom/options";
      var selectbox1_conditions_array = {
        UserInterface: 1
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      this.LoadingFlag = true;
      this.rows = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom/show";
      var upload = {
        UserInterface: 1,
        Lom: this.recordData.LomId
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          if (flag == 1) {
            thisIns.rows = records;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
        });
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var upload = {
          UserInterface: 1,
          Lom: this.LomCode,
          LomName: this.LomName,
          Zone: this.ZoneCode,
          City: this.CityCode,
          LomCharterDate: this.LomCharterDate,
          LomAffiliationDate: this.LomAffiliationDate,
          SponsorLom: this.SponsorLomCode,
          GstNumber: this.GstNumber,
          SponsorDetails: this.SponsorDetails,
          LomStatus: this.LomStatus,
          SponsorType: this.SponsorType
        };
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#area-edit {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>