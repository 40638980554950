<template>
  <div id="lom-list">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-text>
          <h4><span class="text-danger">*</span> indicates required field</h4>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-container>
            <br />
            <v-form ref="form2" v-model="valid1" lazy-validation>
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>
                    <label><span class="text-danger">*</span> IFSC Code</label>
                  </h6>
                  <v-text-field
                    v-model="IfscCode"
                    :rules="IfscCodeRules"
                    placeholder="IFSC Code"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col align="right" cols="12" md="2">
                  <v-btn
                    @click.prevent="searchForm()"
                    color="#7B1FA2"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="btn btn-primary font-size-h6 rounded px-8 py-4 my-7 mr-3 white--text"
                  >
                    Search
                  </v-btn>
                </v-col>
                <v-col align="right" cols="12" md="7" v-if="FormFlag">
                  <v-card class="mx-auto" color="#26c6da" dark max-width="500">
                    <v-card-title>
                      <v-icon large left> mdi-bank </v-icon>
                      <span class="text-h4 font-weight-light"
                        >Bank Details</span
                      >
                    </v-card-title>

                    <v-card-text
                      class="text-h5 font-weight-bold mt-0 white--text"
                      align="left"
                    >
                      <v-row wrap>
                        <v-col cols="12" md="4">Bank Name</v-col>
                        <v-col cols="12" md="1">:</v-col>
                        <v-col cols="12" md="6">{{ BankName }}</v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col cols="12" md="4">Branch Name</v-col>
                        <v-col cols="12" md="1">:</v-col>
                        <v-col cols="12" md="6">{{ BranchName }}</v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col> </v-row
              ><br /><br /><br /><br />
              <v-row wrap v-if="FormFlag">
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>
                    <label
                      ><span class="text-danger">*</span> Account Name</label
                    >
                  </h6>
                  <v-text-field
                    v-model="Name"
                    :rules="NameRules"
                    placeholder="Account  Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>
                    <label
                      ><span class="text-danger">*</span> Account Number</label
                    >
                  </h6>
                  <v-text-field
                    v-model="AccountNumber"
                    :rules="AccountNumberRules"
                    :counter="15"
                    :type="show3 ? 'text' : 'password'"
                    placeholder="Bank Account Number"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>
                    <label
                      ><span class="text-danger">*</span>Re-Enter Account
                      Number</label
                    >
                  </h6>
                  <v-text-field
                    v-model="ReEnterAccountNumber"
                    :rules="ReEnterAccountNumberRules"
                    :counter="15"
                    placeholder="Re-Enter Account Number"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <h6 class="mycard-text1 fsize-4">
                    <span class="text-danger">* </span> Upload Bank Account
                    Document <br />
                    <small
                      >(only jpg, png ,jpeg image files are accepted)</small
                    >
                  </h6>
                  <v-file-input
                    :clearable="false"
                    truncate-length="25"
                    accept="image/png, image/jpeg"
                    placeholder="Upload Event Photo"
                    prepend-icon="mdi-image"
                    append-outer-icon="mdi-delete"
                    v-model="UploadedImage"
                    :rules="ImageRules"
                    @change="addImage(1)"
                  >
                    <template v-slot:append-outer>
                      <v-icon color="red" @click="deleteImage(1)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col align="center" cols="12" sm="6" lg="4" md="4">
                  <h6 class="mycard-text1 fsize-4">Image Edit Options</h6>
                  <p></p>
                  <v-btn-toggle>
                    <v-btn fab small @click.prevent="flip(true, false)">
                      <v-icon>mdi-swap-horizontal-bold</v-icon>
                    </v-btn>
                    <v-btn fab small @click.prevent="flip(false, true)">
                      <v-icon>mdi-swap-vertical-bold</v-icon>
                    </v-btn>
                    <v-btn fab small @click.prevent="rotate(90)">
                      <v-icon>mdi-rotate-right-variant</v-icon>
                    </v-btn>
                    <v-btn fab small @click.prevent="rotate(-90)">
                      <v-icon>mdi-rotate-left-variant</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                  <p></p>
                </v-col>
                <v-col align="center" cols="12" md="4">
                  <h6 class="mycard-text1 fsize-4">
                    Uploaded Document will Appear Here
                  </h6>
                  <p></p>
                  <cropper ref="cropper" :src="SelectedImage.ImageUrl" />
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="right" cols="12" md="6">
                  <v-btn
                    @click.prevent="confirmAlert()"
                    :loading="SubmitFlag"
                    color="green"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="btn btn-primary font-size-h6 px-10 py-4 my-3 mr-3 white--text"
                  >
                    Submit
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    rounded
                    large
                    color="red"
                    class="btn btn-primary font-size-h6 px-10 py-4 my-3 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    BankDetailsCreatePrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      UploadFlag: false,
      rows: [],
      tableColumns1: [],
      selected: [],
      show3: false,
      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",
      FormFlag: false,
      NameRules: [(v) => !!v || "Name is required"],
      Name: "",
      AccountNumber: "",
      AccountNumber: "",
      ReEnterAccountNumber: "",

      AccountNumberRules: [
        (v) => !!v || "Account Number is required",
        (v) => v.length <= 15 || "Account Number must be 15 characters",
      ],

      ReEnterAccountNumberRules: [
        (v) => !!v || "Re-Enter Account Number is required",
        (v) => v <= this.AccountNumber || "Not match.",
      ],
      IfscCodeRules: [(v) => !!v || "IFSC Code is required"],
      IfscCode: "",

      ImageRules: [(v) => !!v || "Image is required"],
      SelectedImage: {},
      UploadedImage: {},

      BankName: "",
      BranchName: "",

      PageInfo: {},
      PageTitle: "LO Bank Details Create",
      PageDescription: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
    },
    IfscCode: function () {
      console.log("watch IfscCode");
      this.FormFlag = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      var data = this.recordData;
      console.log({ data });
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadedImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage.src) {
            URL.revokeObjectURL(this.SelectedImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage = {
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadedImage = {};
          break;

        default:
          break;
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    searchForm() {
      console.log("searchForm called");
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/get-bank-details";
      var upload = {
        IfscCode: this.IfscCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.SubmitFlag = false;

          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          if (flag == 1) {
            thisIns.BankName = records.BankName;
            thisIns.BranchName = records.BranchName;
            console.log("BankName" + thisIns.BankName);
            console.log("BranchName" + thisIns.BranchName);

            thisIns.FormFlag = true;
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        var htmlTxt = "";
        // htmlTxt += "<b>Please verify all the information before proceeding</b>";
        htmlTxt += "<b>Bank Account details</b>";
        htmlTxt += "<br/><br/>";
        htmlTxt += "LO Name : <b> " + this.recordData.LomName + "</b><br/>";
        htmlTxt += "Account  Name : <b>" + this.Name + "</b><br/>";
        htmlTxt += "Bank Name : <b>" + this.BankName + "</b><br/>";
        htmlTxt += "Branch Name : <b>" + this.BranchName + "</b><br/>";
        htmlTxt += "Account Number : <b>" + this.AccountNumber + "</b><br/>";
        htmlTxt += "IFSC Number : <b> " + this.IfscCode + "</b><br/>";
        htmlTxt +=
          "<br/><img src='" +
          this.SelectedImage.ImageUrl +
          "' width=80 height=80/></b><br/>";
        htmlTxt += "<br/><br/>";
        htmlTxt += "Do you want to continue?";
        Swal.fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            this.uploadImage();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    uploadImage() {
      console.log("uploadImage called");
      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        const upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("Name", this.Name);
        upload.append("AccountNumber", this.AccountNumber);
        upload.append("ReEnterAccountNumber", this.ReEnterAccountNumber);
        upload.append("IfscCode", this.IfscCode);
        upload.append("Lom", this.recordData.LomId);

        var UploadedImageName = this.UploadedImage.name;
        UploadedImageName =
          UploadedImageName == (null || undefined) ? "" : UploadedImageName;
        if (UploadedImageName != "") {
          const { canvas } = this.$refs.cropper.getResult();
          if (canvas) {
            canvas.toBlob((blob) => {
              upload.append("Image", blob, UploadedImageName);
              this.UploadFlag = true;
              this.submitForm(upload);
            }, "image/jpeg");
          } else {
            this.submitForm(upload);
          }
        } else {
          this.submitForm(upload);
        }
      } else {
        var message = "";
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        if (MemberId == 0) {
          message += "Kindly select member. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");
      var UploadFlag = this.UploadFlag;
      console.log("UploadFlag=" + UploadFlag);

      if (UploadFlag) {
        this.SubmitFlag = true;
        this.ResultFlag = false;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-bank-details/store";

        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        console.log("upload=" + JSON.stringify(upload));
        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;
          });
      } else {
        var message = "";
        if (!UploadFlag) {
          message += "File is not selected";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>