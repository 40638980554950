<template>
  <div id="add-chairman-data">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitData"
          >
            <v-card-text>
              <v-container>
                <v-row wrap> </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      Upload Image
                      <small> (optional)</small>
                      <p class="notes-text1">
                        (only jpg, png image files are accepted)
                      </p>
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg"
                      placeholder="Upload Chairman Photo"
                      prepend-icon="mdi-account-box"
                      append-outer-icon="mdi-delete"
                      v-model="UploadImage1"
                      @change="addImage(1)"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="deleteImage(1)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col align="center" cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">Image Edit Options</h6>
                    <br />
                    <div class="actions">
                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="flip(true, false)"
                        >
                          <v-icon>fa fa-caret-right</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="flip(false, true)"
                        >
                          <v-icon>fa fa-caret-up</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="rotate(90)"
                        >
                          <v-icon>fa fa-redo</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="rotate(-90)"
                        >
                          <v-icon>fa fa-undo</v-icon>
                        </v-btn>
                      </b-button-group>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      Uploaded Image will Appear Here
                    </h6>
                    <cropper
                      ref="cropper"
                      :src="LomImage.ImageUrl"
                      :stencil-props="{
                        handlers: {},
                        movable: false,
                        scalable: false,
                      }"
                      :stencil-size="StencilSize"
                      image-restriction="stencil"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="submitData"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    recordData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,

      LoadingFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      pageTitle: "Update Lo Logo",

      FacultyTypeIdRules: [(v) => !!v || "Faculty type is required"],
      FacultyTypeId: "",
      FacultyTypeIdOptions: [],
      FacultyTypeIdOptionsLoading: false,

      MemberTypeIdRules: [(v) => !!v || "Member type is required"],
      MemberTypeId: "",
      MemberTypeIdOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "Member" },
        { value: 2, text: "Non Member" },
      ],
      MemberTypeIdOptionsLoading: false,

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership id is required"],
      MembershipIdLoading: false,

      MemberName: "",
      MemberNameRules: [(v) => !!v || "Chairman name is required"],

      MemberDetails: "",
      MemberDetailsRules: [
        // (v) => !!v || "Faculty details required"
      ],

      MemberData: {},

      image: {
        src: "",
        type: null,
      },

      current_photo: "",

      UploadImage1: {},
      LomImage: { src: null },

      StencilSize: { width: 250, height: 250 },
    };
  },
  computed: {},
  watch: {
    FacultyTypeIdOptions: function () {
      console.log("watch FacultyTypeIdOptions");
      this.LoadingFlag = false;
      this.FacultyTypeIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      //   this.getFacultyTypeIdOptions();
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.LomImage.src) {
            URL.revokeObjectURL(this.LomImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.LomImage = {
            ImageName: file.name,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.MemberImage = {
            ImageName: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadImage1 = {};
          break;

        default:
          break;
      }
    },
    getFacultyTypeIdOptions() {
      console.log("getFacultyTypeIdOptions called");
      this.FacultyTypeIdOptionsLoading = true;
      var selectbox1_source = "FacultyTypeId";
      var selectbox1_destination = "FacultyTypeIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 17,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitData() {
      console.log(" submitData called");
      var message = "";
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        var file = this.UploadImage1;
        var FileType = file.type;
        var ImageName = file.name;
        console.log({ FileType });
        console.log({ ImageName });
        var LomId = this.recordData.LomId;
        console.log("LomId+", LomId);
        var image = "";
        canvas.toBlob((blob) => {
          image = canvas.toDataURL();
          console.log("image=" + image);
          this.selectedImage = image;
          var Lom = this.recordData.LomId;
          console.log("Lom", Lom);
          const formData = new FormData();
          formData.append("Lom", Lom);
          formData.append("LomLogo", blob, ImageName);
          this.uploadImage(formData);
          // Perhaps you should add the setting appropriate file format here
          //  this.closePrompt();
        }, FileType);
      } else {
        message += "Kindly select image. ";
        this.toast("error", message, true);
      }
    },
    uploadImage(upload) {
      console.log("uploadImage is called");
      var ProfilePicture = true;
      // console.log("ProfilePicture=" + JSON.stringify(ProfilePicture));

      if (ProfilePicture) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/lom-logo-picture-upload";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        var Lom = this.recordData.LomId;
        console.log("Lom", Lom);
        var selectedImage = this.selectedImage;

        upload.append("FileId", 1);
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (ProfilePicture == "") {
          message += "File is not selected";
        }
        this.toast("error", message);
        thisIns.closePrompt();
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-chairman-data {
  .mycard-text1 {
    font-family: sans-serif;
  }
  .fsize-4 {
    font-size: 18px !important;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
  .fsize-3 {
    font-size: 16px !important;
  }
}
</style>