<template>
  <div id="lom-list">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="EditRecordPrompt" persistent>
      <lom-edit
        :EditRecordPrompt="EditRecordPrompt"
        :recordData="selectedData"
        @hideDialog="hideEditRecordPrompt"
        v-if="EditRecordPrompt"
      ></lom-edit>
    </v-dialog>

    <v-dialog v-model="OldLomRecordFlag" persistent>
      <lom-create
        @hideDialog="hideCreateRecordPrompt"
        v-if="OldLomRecordFlag"
      ></lom-create>
    </v-dialog>

    <v-dialog v-model="AllLomRecordPrompt" persistent>
      <all-lom-list
        :AllLomRecordPrompt="AllLomRecordPrompt"
        PageTitle="All Lom List"
        @hideDialog="hideAllLomRecordPrompt"
        v-if="AllLomRecordPrompt"
      ></all-lom-list>
    </v-dialog>
    <v-dialog v-model="BankDetailsListPrompt" persistent max-width="75%">
      <lom-bank-details-list
        :BankDetailsListPrompt="BankDetailsListPrompt"
        :recordData="selectedData"
        @hideDialog="hideBankDetailsListPrompt"
        v-if="BankDetailsListPrompt"
      ></lom-bank-details-list>
    </v-dialog>
    <v-dialog v-model="BankDetailsCreatePrompt" persistent max-width="75%">
      <lom-bank-details-create
        :BankDetailsCreatePrompt="BankDetailsCreatePrompt"
        :recordData="selectedData"
        @hideDialog="hideBankDetailsCreatePrompt"
        v-if="BankDetailsCreatePrompt"
      ></lom-bank-details-create>
    </v-dialog>
    <v-dialog v-model="PictureUploadPrompt" persistent max-width="75%">
      <upload-lom-logo
        :recordData="selectedData"
        @hideDialog="hideAddRecordPrompt($event)"
        v-if="PictureUploadPrompt"
      ></upload-lom-logo>
    </v-dialog>
    <v-dialog
      v-model="UploadAffiliationDocumentPrompt"
      persistent
      max-width="70%"
    >
      <affiliation-document-update
        :UploadAffiliationDocument="UploadAffiliationDocumentPrompt"
        :recordData="selectedData"
        @hideDialog="hideAffiliationDocumentPrompt"
        v-if="UploadAffiliationDocumentPrompt"
      ></affiliation-document-update>
    </v-dialog>
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn>-->
      </v-speed-dial>
    </v-container>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ YearName }} - {{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <!-- <v-col cols="12" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  label="Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>-->
              <v-col cols="12" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  :loading="ZoneCodeOptionsLoading"
                  v-model="ZoneCode"
                  label="Zone"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
                <v-btn
                  v-if="TableOptions1.CreateButtonFlag"
                  @click.prevent="addRecord"
                  color="success"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Create</v-btn
                >
                <v-btn
                  @click.prevent="AllLomRecordPrompt = true"
                  color="warning"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >All Lom</v-btn
                >
                <v-btn
                  v-if="ShowOldLomCreateButtonFlag"
                  @click.prevent="OldLomRecordFlag = true"
                  color="#4285f4"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >OLD LOM Create</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="DownloadFlag"
                color="info"
                @click.prevent="
                  generateExcel(
                    rows,
                    ExcelFields,
                    ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="mr-4"
                >Download</v-btn
              >
            </v-col>
            <p></p>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ rows.length }} loms found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :loading="SearchFlag"
                loading-text="Loading... Please wait"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="50"
                :search="search"
                item-key="MemberId"
                :single-select="false"
                :show-select="false"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
              >
                <template v-slot:item.LomLogoPath="{ item }">
                  <img width="100" height="100" :src="item.LomLogoPath" />
                  <!-- <img width="100" height="100" :src="item.ProfilePic" v-if="ProfilePic != ''" /> -->
                </template>
                <template v-slot:[`item.ActiveStatusTxt`]="{ item }">
                  <v-chip :color="item.ActiveStatusColor" draggable dark>{{
                    item.ActiveStatusTxt
                  }}</v-chip>
                </template>
                <template v-slot:[`item.AffiliationStatusTxt`]="{ item }">
                  <v-chip
                    :color="item.AffiliationStatusTxtColor"
                    draggable
                    dark
                    class="px-5"
                    >{{ item.AffiliationStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:[`item.ProfilePic`]="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:[`item.ActiveStatus`]="{ item }">
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    @change="confirmAlert(item)"
                    :items="StatusOptions"
                    :rules="StatusRules"
                    :loading="StatusOptionsLoading"
                    v-model="item.ActiveStatus"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-file-document-edit</v-icon
                      >
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#D81B60"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-file-search</v-icon
                      >
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.BankAccountCreateFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#9E9D24"
                        @click="bankDetailsListData(item)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-bank</v-icon
                      >
                    </template>
                    <span>Bank Details</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#FF9800"
                        @click="bankDetailsCreateData(item)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-bank-plus</v-icon
                      >
                    </template>
                    <span>Add Bank Details</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.AffiliationUploadFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#7209b7"
                        @click="uploadAffiliationDocument(item)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-email</v-icon
                      >
                    </template>
                    <span>Send Affiliation Document</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.AffiliationDocumentFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        :href="item.AffiliationDocumentAssetPath"
                        target="_blank"
                        ><v-icon
                          icon
                          size="25px"
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-download</v-icon
                        ></a
                      >
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        v-bind="attrs"
                        @click="uploadDocument(item)"
                        v-on="on"
                        >mdi mdi-file-upload</v-icon
                      >
                    </template>
                    <span> File Upload</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import LomCreate from "@/view/pages/erp/lom/LomCreate.vue";
import LomEdit from "@/view/pages/erp/lom/LomEdit.vue";
import LomBankDetailsList from "@/view/pages/erp/lom/bank-details/LomBankDetailsList";
import LomBankDetailsCreate from "@/view/pages/erp/lom/bank-details/LomBankDetailsCreate";
import AffiliationDocumentUpdate from "@/view/pages/erp/lom/affilication-document/AffiliationDocumentUpdate.vue";
import AllLomList from "@/view/pages/erp/lom/AllLomList.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import Swal from "sweetalert2";
import UploadLomLogo from "@/view/pages/erp/lom/UploadLomLogo.vue";

export default {
  mixins: [common],
  components: {
    LomCreate,
    LomEdit,
    AllLomList,
    LomBankDetailsList,
    LomBankDetailsCreate,
    AffiliationDocumentUpdate,
    UploadLomLogo,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      BankDetailsListPrompt: false,
      BankDetailsCreatePrompt: false,
      UploadAffiliationDocumentPrompt: false,
      PictureUploadPrompt: false,
      ProgressLoadingFlag: false,
      SpeedDialFlag: false,

      rows: [],
      tableColumns1: [],
      TableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: "",
      selectedData: {},

      AllLomRecordPrompt: false,
      EditRecordPrompt: false,

      ShowOldLomCreateButtonFlag: false,
      OldLomRecordFlag: false,

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: 11,
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      StatusRules: [],
      StatusOptions: [
        { value: 1, text: "Active" },
        { value: 2, text: "Provisional" },
        { value: 3, text: "Suspended" },
        { value: 4, text: "Dissaffiliated" },
      ],
      StatusOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        this.ShowOldLomCreateButtonFlag = RoleId == 2 ? true : false;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    JciYearCode: function () {
      console.log("watch JciYearCode");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("ZoneCode", "ZoneCodeOptions");
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      this.$session.set("LomListZoneCode", this.ZoneCode);
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    FromLomCodeOptions: function () {
      console.log("watch FromLomCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.FromLomCodeOptionsLoading = false;
    },
    ToZoneCodeOptions: function () {
      console.log("watch ToZoneCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToZoneCodeOptionsLoading = false;
    },
    ToZoneCode: function () {
      console.log("watch ToZoneCode");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToLomCodeOptionsLoading = true;
      this.getLomCodeOptions("ToLomCode", "ToLomCodeOptions", this.ToZoneCode);
    },
    ToLomCodeOptions: function () {
      console.log("watch ToLomCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToLomCodeOptionsLoading = false;
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "list_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.ZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("ZoneCode", "ZoneCodeOptions");
      this.getTableRecords();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    bankDetailsListData(tr) {
      console.log("bankDetailsListData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.BankDetailsListPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one lom to list";
        this.toast("error", message);
      }
    },
    bankDetailsCreateData(tr) {
      console.log("bankDetailsCreateData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.BankDetailsCreatePrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one lom to create";
        this.toast("error", message);
      }
    },
    uploadAffiliationDocument(tr) {
      console.log("UploadAffiliationDocument called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.UploadAffiliationDocumentPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one lom to create";
        this.toast("error", message);
      }
    },
    uploadDocument(tr) {
      console.log("UploadDocument called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.PictureUploadPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one lom to create";
        this.toast("error", message);
      }
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called", flag);
      this.PictureUploadPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    hideBankDetailsListPrompt() {
      console.log("hideBankDetailsListPrompt called");
      this.BankDetailsListPrompt = false;
    },
    hideBankDetailsCreatePrompt(flag) {
      console.log("hideBankDetailsCreatePrompt called");
      this.BankDetailsCreatePrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    hideAffiliationDocumentPrompt(flag) {
      console.log("hideAffiliationDocumentPrompt called");
      this.UploadAffiliationDocumentPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.EditRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one city to edit";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.EditRecordPrompt = false;
      this.searchForm();
    },
    hideCreateRecordPrompt() {
      console.log("hideCreateRecordPrompt called");
      this.OldLomRecordFlag = false;
      this.searchForm();
    },
    hideAllLomRecordPrompt() {
      console.log("hideAllLomRecordPrompt called");
      this.AllLomRecordPrompt = false;
      this.searchForm();
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var url = "/lom/details";
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        var params = {
          id: LomId,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one lom to preview";
        this.toast("error", message);
      }
    },
    getZoneCodeOptions(source, destination) {
      console.log("getZoneCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var CurrentYearId = this.JciYearCode;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "" && CurrentYearId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: CurrentYearId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions(source, destination, ZoneCode) {
      console.log("getLomCodeOptions called");
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", ZoneCode=" +
          ZoneCode
      );
      var CurrentYearId = this.CurrentYearId;
      if (ZoneCode != "" && CurrentYearId != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: CurrentYearId,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = this.YearCode;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var SearchId = this.SearchId;
      var UserInterface = SearchId == 1 ? 1 : 3;
      console.log(
        "validate1=" +
          validate1 +
          ", SearchId=" +
          SearchId +
          ", UserInterface=" +
          UserInterface
      );

      if (validate1) {
        this.getTableRecords();
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      this.SearchFlag = true;
      this.rows = [];
      this.zone = {};
      this.lom = {};

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/year-wise-lom/lom-lists";
      var upload = {
        UserInterface: 3,
        YearCode: this.JciYearCode,
        ZoneCode: this.ZoneCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = false;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          var TableContent = response.data.TableContent;
          thisIns.tableColumns1 = TableContent.TableHeader;

          var options = TableContent.TableOptions;
          thisIns.TableOptions1 = options;

          if (flag == 1) {
            thisIns.toast("success", output);
            thisIns.rows = TableContent.TableData;
            thisIns.DownloadFlag = options.DownloadFlag;
            thisIns.ExcelFileName = options.ExcelFileName;
            thisIns.ExcelFields = options.ExcelFields;
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
        });
    },
    confirmAlert(item) {
      Swal.fire({
        title: "Do you want to continue?",
        text: "Please verify all the information before proceeding.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Continue`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#d33",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.updateActiveStatus(item);
        } else {
          this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    updateActiveStatus(item) {
      console.log("updateActiveStatus is called");

      var validate1 = this.$refs.form2.validate();
      var LomId = item.LomId;
      var ActiveStatus = item.ActiveStatus;
      console.log(
        "validate1=" +
          validate1 +
          "LomId=" +
          LomId +
          "ActiveStatus=" +
          ActiveStatus
      );

      if (validate1 && LomId != "" && ActiveStatus != "") {
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/status-update";
        var upload = {
          UserInterface: 1,
          Year: this.JciYearCode,
          Zone: this.ZoneCode,
          Lom: LomId,
          Status: ActiveStatus,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.searchForm();
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>