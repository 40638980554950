<template>
  <div id="lom-list">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-text>
          <h4><span class="text-danger">*</span> indicates required field</h4>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-container>
            <br />
            <v-form ref="form2" v-model="valid1" lazy-validation>
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label class="my-txt1">LO Name</label>
                  <p class="my-txt2">{{ recordData.LomName }}</p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label class="my-txt1">Sponsored</label>
                  <p class="my-txt2">{{ recordData.SponsorDetails }}</p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label class="my-txt1">Affiliation Date</label>
                  <p class="my-txt2">{{ recordData.AffiliationDateTxt }}</p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label class="my-txt1">State</label>
                  <p class="my-txt2">{{ recordData.StateName }}</p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label class="my-txt1">District</label>
                  <p class="my-txt2">{{ recordData.DistrictName }}</p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label class="my-txt1">City</label>
                  <p class="my-txt2">{{ recordData.CityName }}</p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label class="my-txt1">Status</label>
                  <p>
                    <v-chip :color="recordData.ActiveStatusColor" draggable dark
                      >{{ recordData.ActiveStatusTxt }}
                    </v-chip>
                  </p>
                </v-col>
              </v-row>
              <!-- <v-row wrap>
                <v-col cols="12" md="12" lg="12" sm="12" v-if="AffiliationDocumentArray.length>0">
                  <h6 class="mycard-text1 fsize-3">
                    Old Affilication Document
                  </h6>
                  <div
                    v-for="(item, index) in AffiliationDocumentArray"
                    :key="index"
                    class="text-h6 mt-4"
                  >
                   {{ index + 1 }} .
                    <a :href="item.AffiliationDocumentPath" target="_blank"
                      >Download Affiliation Document {{ index+1 }}</a
                    >
                  </div>
                </v-col>
                <v-col cols="12" sm="6" lg="6" md="6" id="step11">
                  <h6 class="mycard-text1 fsize-3">
                    <span class="text-danger">*</span> Upload PDF
                  </h6>
                  <v-file-input
                    :clearable="false"
                    truncate-length="25"
                    accept="application/pdf"
                    placeholder="Upload pdf"
                    v-model="UploadedFile"
                    :rules="UploadedFileRules"
                    prepend-icon="mdi-file-pdf"
                  >
                    <template v-slot:append-outer>
                      <v-icon color="red" @click="UploadedFile = {}">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row> -->
              <v-row wrap>
                <!-- <v-col align="right" cols="12" md="6">
                  <v-btn
                    @click.prevent="submitForm()"
                    :loading="SubmitFlag"
                    color="green"
                    shaped
                    large
                    class="btn btn-primary font-size-h6 px-5 py-3 my-3 mr-3 white--text"
                  >
                    Submit
                  </v-btn>
                </v-col> -->
                <v-col cols="12" md="2">
                  <v-btn
                    @click.prevent="sendCertificate(1)"
                    color="pink"
                    shaped
                    large
                    class="btn btn-primary font-size-h6 px-5 py-3 my-3 mr-3 white--text"
                  >
                    Approved
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    @click="closePrompt"
                    shaped
                    large
                    color="red"
                    class="btn btn-primary font-size-h6 px-6 py-4 my-3 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  props: {
    UploadAffiliationDocument: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      UploadFlag: false,
      ResultFlag: false,
      FormFlag: false,
      rows: [],
      tableColumns1: [],
      selected: [],
      show3: false,
      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",
      LomName: "",
      SponsorDetails: "",
      AffiliationDateTxt: "",
      AffiliationDocumentRequestStatus: "",
      AffiliationData: "",
      ActiveStatusColor: "",

      StatusRules: [],
      StatusOptions: [
        { value: 1, text: "Active" },
        { value: 2, text: "Provisional" },
        { value: 3, text: "Suspended" },
        { value: 4, text: "Dissaffiliated" },
      ],
      StatusOptionsLoading: false,

      Name: "",
      UploadedFileRules: [(v) => !!v || "File is required"],

      UploadedFile: {},
      AffiliationDocumentArray: [],

      PageInfo: {},
      PageTitle: "Update Affiliation Document",
      PageDescription: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      console.log("recordData" + JSON.stringify(this.recordData));
      // this.getAffiliationDocuments();
      // this.updateActiveStatus();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    getAffiliationDocuments() {
      console.log("getLocationRecords is called");

      var LomId = this.recordData.LomId;
      console.log({ LomId });

      this.SearchFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom/affiliation-document-list";
      var upload = {
        UserInterface: 2,
        Lom: LomId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.AffiliationData = records;
            thisIns.AffiliationDocumentArray = records.AffiliationDocumentArray;
            console.log(
              "AffiliationDocumentArray=" +
                JSON.stringify(thisIns.AffiliationDocumentArray)
            );

            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    updateActiveStatus(item) {
      console.log("updateActiveStatus is called");

      var validate1 = this.$refs.form2.validate();
      var LomId = item.LomId;
      var ActiveStatus = item.ActiveStatus;
      console.log(
        "validate1=" +
          validate1 +
          "LomId=" +
          LomId +
          "ActiveStatus=" +
          ActiveStatus
      );

      if (validate1 && LomId != "" && ActiveStatus != "") {
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/status-update";
        var upload = {
          UserInterface: 1,
          Year: this.JciYearCode,
          Zone: this.ZoneCode,
          Lom: LomId,
          Status: ActiveStatus,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.searchForm();
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        const upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append(
          "LomPresidentMemberId",
          this.recordData.LomPresidentMemberId
        );
        upload.append("Lom", this.recordData.LomId);
        upload.append("YearwiseLom", this.recordData.YearwiseLomId);
        upload.append("UploadedFile", this.UploadedFile);

        this.SubmitFlag = true;
        this.ResultFlag = false;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/add-affiliation-document";

        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        console.log("upload=" + JSON.stringify(upload));
        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error");
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;
          });
      } else {
        var message = "";
        if (!UploadFlag) {
          message += "Upload data not found";
        }
        this.sweetAlert("error", message, false);
      }
    },
    sendCertificate(type) {
      console.log("send_certificate is called");
      console.log({ type });

      var tr = this.recordData;
      console.log({ tr });

      var n1 = Object.keys(tr).length;
      console.log({ n1 });

      if (n1 > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/send-certificate";

        var upload = {
          UserInterface: 1,
          LoName: tr.LomId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        this.LoadingFlag = true;
        this.OverlayLoadingFlag = true;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
  .my-txt2 {
    font-size: 14px;
    // font-weight: bold;
  }
  .my-txt2 {
    font-size: 16px;
    // font-weight: bold;
  }
}
</style>