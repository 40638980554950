<template>
  <div id="lom-list">
    <v-dialog v-model="BankDetailsEditPrompt" persistent max-width="80%">
      <lom-bank-details-edit
        :BankDetailsEditPrompt="BankDetailsEditPrompt"
        :recordData="selectedData"
        PageTitle="Bank Details Edit"
        @hideDialog="hideBankDetailsEditPrompt"
        v-if="BankDetailsEditPrompt"
      ></lom-bank-details-edit>
    </v-dialog>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-text>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-container>
            <br />
            <v-row wrap v-for="item in data1" :key="item.LomBankAccountId">
              <v-col cols="12" md="6" align="center">
                <v-img
                  :src="item.LomBankAccountImagePath"
                  max-width="200"
                  max-height="200"
                ></v-img>
                <br /><br /><br />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="4"> <h4>LO Name</h4> </v-col>
                  <v-col cols="12" md="1">:</v-col>
                  <v-col cols="12" md="7">
                    <font size="4">{{ recordData.LomName }}</font>
                  </v-col>
                  <v-col cols="12" md="4"> <h4>Account Name</h4> </v-col>
                  <v-col cols="12" md="1">:</v-col>
                  <v-col cols="12" md="5">
                    <font size="4">{{ item.LomBankAccountName }}</font>
                  </v-col>
                  <v-col cols="12" md="4"> <h4>Bank Name</h4> </v-col>
                  <v-col cols="12" md="1">:</v-col>
                  <v-col cols="12" md="5">
                    <font size="4">{{ item.BankName }}</font>
                  </v-col>
                  <v-col cols="12" md="4"> <h4>Account Number</h4> </v-col>
                  <v-col cols="12" md="1">:</v-col>
                  <v-col cols="12" md="5">
                    <font size="4">{{ item.LomBankAccountNumber }}</font>
                  </v-col>
                  <v-col cols="12" md="4"> <h4>IFSC code</h4> </v-col>
                  <v-col cols="12" md="1">:</v-col>
                  <v-col cols="12" md="5">
                    <font size="4">{{ item.LomBankAccountIfscCode }}</font>
                  </v-col>
                  <v-col cols="12" md="4"> <h4>Branch Name</h4> </v-col>
                  <v-col cols="12" md="1">:</v-col>
                  <v-col cols="12" md="5">
                    <font size="4">{{ item.BranchName }}</font>
                  </v-col>
                  <v-col cols="12" md="4"> <h4>Bank Address</h4> </v-col>
                  <v-col cols="12" md="1">:</v-col>
                  <v-col cols="12" md="5">
                    <font size="4">{{ item.BankAddress }}</font>
                  </v-col>
                </v-row>
                <!-- 
                <h6>
                  Status :
                  <v-chip :color="item.ActiveStatusTxtColor" draggable dark>
                    {{ item.ActiveStatusTxt }}
                  </v-chip>
                </h6> -->
              </v-col>
              <v-col
                align="center"
                class="mt-4"
                cols="12"
                lg="12"
                md="12"
                sm="12"
              >
                <v-btn
                  @click.prevent="bankDetailsEditData(item)"
                  elevation="30"
                  shaped
                  tile
                  rounded
                  small
                  color="#1db954"
                  class="font-size-h6 px-10 mr-3 white--text"
                  >Edit</v-btn
                >
                <v-btn
                  @click="closePrompt"
                  elevation="30"
                  shaped
                  tile
                  rounded
                  small
                  color="red"
                  class="font-size-h6 px-8 mr-3 white--text"
                >
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import LomBankDetailsEdit from "@/view/pages/erp/lom/bank-details/LomBankDetailsEdit";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    LomBankDetailsEdit,
  },
  props: {
    BankDetailsListPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      BankDetailsFlag: true,
      BankDetailsEditPrompt: false,
      ProgressLoadingFlag: false,
      SpeedDialFlag: false,

      rows: [],
      tableColumns1: [],
      TableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: "",
      selectedData: {},
      data1: {},

      EditRecordPrompt: false,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      PageInfo: {},
      PageTitle: "LO Bank Details List",
      PageDescription: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      this.getBankDetails();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    bankDetailsEditData(tr) {
      console.log("bankDetailsEditData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.BankDetailsEditPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one lom to edit";
        this.toast("error", message);
      }
    },
    hideBankDetailsEditPrompt(flag) {
      console.log("hideBankDetailsEditPrompt called");
      this.BankDetailsEditPrompt = false;

      if (flag) {
        this.getBankDetails();
        this.closePrompt();
      }
    },
    getBankDetails() {
      console.log("getBankDetails called");
      var data = this.recordData;
      console.log({ data });
      this.LoadingFlag = false;
      var LomId = data.LomId;
      console.log({ LomId });
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/lom-bank-details/lists";
      var upload = {
        UserInterface: 1,
        Lom: LomId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            // thisIns.toast("success", output);
            thisIns.data1 = records.TableData;
            console.log("data1=" + JSON.stringify(thisIns.data1));
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
        });
    },
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>